import { currentEditorLocale$ as i18nComponentsCurrentEditorLocale$ } from "@smallstack/i18n-components";
import { BehaviorSubject } from "rxjs";

export const smallstackApiUrl: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
export const smallstackUrl: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

export const currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
export const currentProjectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

export const backofficeType$: BehaviorSubject<"reseller" | "project" | "company" | "user"> = new BehaviorSubject(
  "user"
);

/**
 * @deprecated use @smallstack/i18n-components -> currentEditorLocale$ directly
 */
export const currentEditorLocale$ = i18nComponentsCurrentEditorLocale$;
export const paymentProvider$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
