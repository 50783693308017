import { Injectable } from "@angular/core";
import { AxiosApiClient, ApiClientFactory as IApiClientFactory } from "@smallstack/axios-api-client";
import { ContextService } from "@smallstack/common-components";
import { currentCompanyId$, currentProjectId$, smallstackApiUrl } from "../../../app.subscriptions";
import { reseller$ } from "../../common/store/reseller.store";

/**
 * Helper Classes for Angular DI
 */

/**
 * Accesses the cloud api in the context of the current project
 */
@Injectable()
export class ProjectApiClient extends AxiosApiClient {
  constructor() {
    super({} as any);
    throw new Error("USED THE WRONG ONE");
  }
}

/**
 * Accesses the cloud api in the context of the current company
 */
@Injectable()
export class CompanyApiClient extends AxiosApiClient {
  constructor() {
    super({} as any);
    throw new Error("USED THE WRONG ONE");
  }
}

/**
 * Accesses the cloud api in the context of the current reseller
 */
@Injectable()
export class ResellerApiClient extends AxiosApiClient {
  constructor() {
    super({} as any);
    throw new Error("USED THE WRONG ONE");
  }
}

/**
 * Accesses the cloud api in the context of the current user
 */
@Injectable()
export class UserApiClient extends AxiosApiClient {
  constructor() {
    super({} as any);
    throw new Error("USED THE WRONG ONE");
  }
}

@Injectable({
  providedIn: "root"
})
export class ApiClientFactory implements IApiClientFactory {
  private resellerClient: ResellerApiClient;
  private companyClient: CompanyApiClient;
  private projectClient: ProjectApiClient;
  private userClient: UserApiClient;

  constructor(private contextService: ContextService) {}

  public reseller(): ResellerApiClient {
    if (!this.resellerClient) {
      this.resellerClient = new AxiosApiClient({
        apiUrl: smallstackApiUrl.value,
        tenantId: () => this.getResellerId(),
        resellerId: () => this.getResellerId(),
        token: () => this.contextService.getEvaluatedContext().token
      });
    }
    return this.resellerClient;
  }

  public company(): CompanyApiClient {
    if (!this.companyClient) {
      this.companyClient = new AxiosApiClient({
        apiUrl: smallstackApiUrl.value,
        tenantId: () => currentCompanyId$.value,
        resellerId: () => this.getResellerId(),
        token: () => this.contextService.getEvaluatedContext().token
      });
    }
    return this.companyClient;
  }

  public project(): ProjectApiClient {
    if (!this.projectClient) {
      this.projectClient = new AxiosApiClient({
        apiUrl: smallstackApiUrl.value,
        tenantId: () => currentProjectId$.value,
        resellerId: () => this.getResellerId(),
        token: () => this.contextService.getEvaluatedContext().token
      });
    }
    return this.projectClient;
  }

  public user(): UserApiClient {
    if (!this.userClient) {
      this.userClient = new AxiosApiClient({
        apiUrl: smallstackApiUrl.value,
        tenantId: () => this.contextService.getEvaluatedContext().userId,
        resellerId: () => this.getResellerId(),
        token: () => this.contextService.getEvaluatedContext().token
      });
    }
    return this.userClient;
  }

  private getResellerId(): string {
    if (reseller$.value) return reseller$.value.id;
    throw new Error("no reseller set");
  }
}
